import {
  TComponent,
  TComponentProps,
  TGroupsebComponent,
  TGroupsebTemplateConfig,
  TStore
} from "@/store/builder-pro/types";
import {
  EGroupsebTemplates,
  GROUPSEB_TEMPLATES
} from "@/store/builder-pro/consts/groupseb-templates";
import {cloneDeep} from "lodash-es";
import {flattenComponents} from "@/store/builder-pro/utils/flatten-components";
import {normalizeParentIds} from "@/store/builder-pro/utils/normalize-parent-ids";
import {constructNestedComponents} from "@/store/builder-pro/utils/construct-nested-components";
import {EEditorSizes} from "@/store/builder-pro/consts";

type THandleAddingGroupsebTemplateArgs = {
  builderPro: TStore,
  fromTemplate: string | undefined,
  toTemplate: string,
}

type TGetSyncedComponentsWithGroupsebTemplateArgs = {
  newTemplateComponents: TGroupsebComponent[];
  initialComponents: TGroupsebComponent[];
}

type TGetSyncedComponentsWithGroupsebTemplateConfigArgs = {
  groupsebTemplateConfig: TGroupsebTemplateConfig,
  initialComponents: TGroupsebComponent[];
}

type TAddSyncedGroupsebComponentPayload = {
  groupsebComponent: TGroupsebComponent;
  initialComponent: TComponent;
  syncedComponents: TComponent[];
}

type TSyncGroupsebComponentPayload = {
  groupsebComponent: TGroupsebComponent,
  initialComponent: TComponent;
  matchInitialChildrenCount: boolean | undefined;
}

const syncGroupsebComponent = (payload: TSyncGroupsebComponentPayload) => {
  const { groupsebComponent, initialComponent, matchInitialChildrenCount } = payload;

  // override props
  groupsebComponent.props = {
    ...initialComponent.props,
    ...(
        groupsebComponent.props
          ? Object.entries(groupsebComponent.props)
            .filter(([, prop]) => prop?.overrideWithGroupsebProp)
          : []
      )
      .reduce((props, [propKey, prop]) => {
        if (!prop) {
          return props;
        }

        const {overrideWithGroupsebProp, ...rawProp} = prop;

        return ({
          ...props,
          [propKey]: rawProp
        })
      }, {} as TComponentProps)
  }

  // sync the nested children as well
  if (groupsebComponent.children) {
    // if groupsebChildren must match the number of children in the initial component,
    //  then populate the groupsebChildren with copies
    if (matchInitialChildrenCount && groupsebComponent.children.length < (initialComponent.children?.length || 0)) {
      const childrenCountDifference = initialComponent.children!.length - groupsebComponent.children.length;

      for (let i = 0; i < childrenCountDifference; i += 1) {
        groupsebComponent.children.push(cloneDeep(groupsebComponent.children[0]));
      }
    }

    // nesting children logic
    if (initialComponent.children?.length) {
      for (let childIndex = 0; childIndex < groupsebComponent.children.length; childIndex += 1) {
        const groupsebComponentChild = groupsebComponent.children[childIndex];
        const initialComponentChild = initialComponent.children[childIndex];

        if (!initialComponentChild) break;

        syncGroupsebComponent({
          groupsebComponent: groupsebComponentChild,
          initialComponent: initialComponentChild,
          matchInitialChildrenCount: groupsebComponentChild.matchInitialChildrenCount,
        })
      }
    }
  }
}

const addGroupsebComponent = (payload: TAddSyncedGroupsebComponentPayload) => {
  const { groupsebComponent, initialComponent, syncedComponents } = payload;

  const {
    possibleMatchComponents,
    matchInitialChildrenCount,
    ...rawGroupsebComponent
  } = cloneDeep(groupsebComponent);

  syncedComponents.push(rawGroupsebComponent);
  const addedGroupsebComponent = syncedComponents[syncedComponents.length - 1]

  syncGroupsebComponent({
    groupsebComponent: addedGroupsebComponent,
    initialComponent,
    matchInitialChildrenCount,
  })
}

const getSyncedComponentsWithGroupsebTemplate = ({
  newTemplateComponents,
  initialComponents: unsortedInitialComponents,
}: TGetSyncedComponentsWithGroupsebTemplateArgs): TComponent[] => {
  const initialComponents = unsortedInitialComponents
    .slice()
    .sort((a, b) =>
      (a.order || 0) > (b.order || 0) ? 1 : -1
    )
  const syncedComponents: TComponent[] = [];

  let lastMatchedNewTemplateComponentIndex = -1;

  // go through new template components
  for (
    let traversedNewTemplateComponentIndex = 0;
    traversedNewTemplateComponentIndex < newTemplateComponents.length;
    traversedNewTemplateComponentIndex += 1
  ) {
    const groupsebComponent = newTemplateComponents[traversedNewTemplateComponentIndex];
    const initialComponent = initialComponents[0];

    const componentsMatch = (
      groupsebComponent.componentKey === initialComponent.componentKey
      || groupsebComponent.possibleMatchComponents?.includes(initialComponent.componentKey)
    );

    if (componentsMatch) {
      lastMatchedNewTemplateComponentIndex = traversedNewTemplateComponentIndex;
      // remove the component from initial components since it's been synced
      initialComponents.splice(0, 1);
      // add the component to the groupsebComponents
      addGroupsebComponent({
        groupsebComponent,
        initialComponent,
        syncedComponents,
      })
    }
  }

  // if the last matched component is not the last in the new template components, then add them to the syncedComponents
  if (lastMatchedNewTemplateComponentIndex !== -1 && lastMatchedNewTemplateComponentIndex < newTemplateComponents.length - 1) {
    syncedComponents.push(
      ...newTemplateComponents.slice(lastMatchedNewTemplateComponentIndex + 1)
        .map((component) => ({
          ...cloneDeep(component),
        }))
    )
  }

  return syncedComponents;
}

const getSyncedComponentsWithGroupsebTemplateConfig = ({
  groupsebTemplateConfig,
  initialComponents
}: TGetSyncedComponentsWithGroupsebTemplateConfigArgs): TComponent[] => {
  const components = cloneDeep(initialComponents);

  components.forEach(component => {
    groupsebTemplateConfig.propManipulations.forEach(propManipulation => {
      if (component.props?.[propManipulation.propKey]) {
        if (propManipulation.replaceValue) {
          component.props[propManipulation.propKey]!.value = !(typeof propManipulation.newValue === 'string') ? propManipulation.newValue : {
            [EEditorSizes.DESKTOP]: propManipulation.newValue
          }
        }
        if (propManipulation.replaceDefaultValue) {
          let newDefaultValue: string;
          if (propManipulation.newDefaultValue !== undefined) {
            newDefaultValue = propManipulation.newDefaultValue
          } else {
            newDefaultValue = (typeof propManipulation.newValue === 'string')
              ? propManipulation.newValue
              : propManipulation.newValue[EEditorSizes.DESKTOP] || Object.values(propManipulation.newValue)[0] as string;
          }

          component.props[propManipulation.propKey]!.defaultValue = newDefaultValue;
        }
      }
    })
  })

  return components;
}

// This function transfers components from the default page in the given template to a new groupseb template.
//  If a component doesn't match the structure of the groupseb template, it's skipped. Missing components
//  will be added to the end of the components' structure to match the groupseb template.
//  If a component matches a component from the groupseb template,
//  its props will be updated with the props from the groupseb template matched component.
//  This function ignores all pages other than the default page
//  (default page = index.html or the first found language if index.html doesn't exist).
//  Returns whether added the template or not.
const handleAddingGroupsebTemplate = ({
  builderPro,
  fromTemplate,
  toTemplate,
}: THandleAddingGroupsebTemplateArgs): boolean => {
  if (
    fromTemplate
    && toTemplate
    && fromTemplate !== toTemplate
    && builderPro.epackData.components?.[builderPro.activeLocale]?.[fromTemplate]
    && !builderPro.epackData.components[builderPro.activeLocale][toTemplate]
  ) {
    const fromPage: string | undefined = builderPro.epackData.components[builderPro.activeLocale][fromTemplate]['index.html']
      ? 'index.html'
      : Object.keys(builderPro.epackData.components[builderPro.activeLocale][fromTemplate])[0];

    if (fromPage) {
      //const groupsebTemplate = GROUPSEB_TEMPLATES[toTemplate as EGroupsebTemplates];

      let syncedComponents: TComponent[] = [];

      // if (groupsebTemplate instanceof Array) {
      //   syncedComponents = flattenComponents(getSyncedComponentsWithGroupsebTemplate({
      //     newTemplateComponents: groupsebTemplate as TGroupsebComponent[],
      //     initialComponents: constructNestedComponents(normalizeParentIds(
      //       builderPro.epackData.components[builderPro.activeLocale][fromTemplate][fromPage]
      //     ))
      //   }))
      // } else if (!!groupsebTemplate.propManipulations) {
      //   syncedComponents = getSyncedComponentsWithGroupsebTemplateConfig({
      //     groupsebTemplateConfig: groupsebTemplate,
      //     initialComponents: builderPro.epackData.components[builderPro.activeLocale][fromTemplate][fromPage],
      //   })
      // }
      syncedComponents = builderPro.epackData.components[builderPro.activeLocale][fromTemplate][fromPage];
      builderPro.epackData.components[builderPro.activeLocale][toTemplate] = {
        'index.html': syncedComponents
      };

      return true;
    }
  }

  return false;
}

export { handleAddingGroupsebTemplate }
